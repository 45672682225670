<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import General from '@/services/General';
import Swal from "sweetalert2";
import modalAddHypePopUp from '@/components/modals/app/modalAddHypePopUp';
import modalEditHypePopUp from '@/components/modals/app/modalEditHypePopUp';

export default {
  components: { Layout, PageHeader, modalAddHypePopUp, modalEditHypePopUp },
  page: {
      title: "Application Marketing Popup",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  data() {
    return {
        title: "Marketing Popup",
        items: [
            {
              text: "Application",
              href: "/app",
            },
            {
              text: "Marketing Popup",
              active: true,
            },
        ],
        isBusy: false,
        error: null,
        showModal:false,
        modalData:{},
        link_id:null,
        tableData: [],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "pdate_formatted",
        sortDesc: true,
        fields: [
            {
                key: "id",
                label: "ID",
                sortable: true,
            },
            {
                key: "name",
                label: "Name",
                sortable: false,
            },
            {
                key: "image",
                label: "Image",
                sortable: false,
            },
            {
                key: "action",
                label: "Action",
                sortable: true,
            },
            {
                key: "action_text",
                label: "Action Text",
                sortable: true,
            },
            {
                key: "type" ,
                label : "Type" ,
                sortable : false ,
            },
            {
                key: "date_from",
                label: "Date From",
                sortable: true,
            },
            {
                key: "date_to",
                label: "Date To",
                sortable: true,
            },
            "edit",
            "delete",
        ],
    };
  },
  async created() {
      await this.getHypePopUp()
  },
  methods: {
      modalAddHypePopUp(){
          this.$bvModal.show("add_new_hype_popup")
      },
      callmodalEditHypePopUp(item){
          this.$bvModal.show("edit_hype_popup")
          this.modalData = item
      },
      async getHypePopUp(){
          try {
              this.toggleBusy();
              const response = await General.getHypePopUp()
              this.tableData = response.data.popups
              this.totalRows = this.tableData.length
              this.toggleBusy();
          } catch (error) {
              this.toggleBusy();
              this.error = error.response.data.error ? error.response.data.error : "";
              this.tableData = []
              this.totalRows = 0;
          }
      },
      onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
      },
      deleteHypePopUp(id){
            Swal.fire({
                title: "Delete Marketing Popup?",
                text: "This Marketing Popup will be deleted!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Remove!",
            }).then((result) => {
                if (result.value) {
                      General.deleteHypePopUp(id)
                          .then((response) => {
                              const res = response.data ? response.data : false;
                              const error = response.data.error ? response.data.error : 'Failed';
                              if(res){
                                  this.getHypePopUp()
                                  Swal.fire("Remove PopUp", "Marketing Popup successfully removed!", "success");
                              }else{
                                  Swal.fire("Fail!", error, "warning");
                              }
                          })
                          .catch(error => {
                              this.error = error.response.data.error ? error.response.data.error : "Delete Failed";
                              this.failedmsg(this.error)
                          })
                }
            });
        },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row mt-4">
                    <div class="col-sm-12 col-md-6 mb-5">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center me-5">
                                Show&nbsp;
                                <b-form-select
                                        v-model="perPage"
                                        size="sm"
                                        :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddHypePopUp">
                                <i class="mdi mdi-plus me-2"></i> 
                                Add New Marketing Popup
                            </a>
                        </div>
                        
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                                id="tickets-table_filter"
                                class="dataTables_filter text-md-end"
                        >
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                            :busy="isBusy"
                            :items="tableData"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            show-empty
                            empty-text="No Data Found"
                            @filtered="onFiltered"
                    >

                        <template v-slot:cell(action)="data">
                            <a :href="data.item.action" target="_blank">{{data.item.action.slice(0, 50)}}</a>
                        </template>

                        <template v-slot:cell(image)="data">
                            <div class="flex-shrink-0">
                                <img class="img-fluid table-images" :src="data.item.image" width="50">
                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callmodalEditHypePopUp(data.item)">
                                         <i class="uil uil-pen font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove" @click="deleteHypePopUp(data.item.id)">
                                        <i class="uil uil-trash-alt font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>

                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                    </b-table>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>


    <!-- MODALS -->
    <modalAddHypePopUp  @onRefresh="getHypePopUp"></modalAddHypePopUp>
    <modalEditHypePopUp @onRefresh="getHypePopUp" :popupsData="modalData"></modalEditHypePopUp>
    <!-- /MODALS -->

  </Layout>
</template>
